import React, { Component }  from "react";
import { Constant } from "../../helpers/Constant";
import ReactPlayer from 'react-player'
import Navbar from "../../components/Navbars/AuthNavbar.js";
import Footer from "../../components/Footers/Footer.js";
import PageBg from '../static-pages/PageBg';
import axios from "axios";
import Toaster from "../../helpers/Toaster";
import PageHeadingImage from '../static-pages/PageHeadingImage';

class VideoView extends Component {
    constructor(props) {
        super();
        this.state = {
          videoDetail: [],
        };
      }
    componentDidMount() {
        this.videoView();
      }
    
      videoView() {
        let module = window.location.pathname;
        // var module = this.props.location.pathname;
        var video = module.split('/').at(-1);

        axios
          .get(Constant.server_url + `video-detail/` + video)
          .then((res) => {
              var videoDetails = res.data.data;
              if(videoDetails.length > 0)
              {
                this.setState({ videoDetail: videoDetails });
              }
            
          })
          .catch((err) => {
            new Toaster({ type: "error", msg: "Something Went Wrong." });
          });
      }
      render() {
    return (
        <>
            <Navbar transparent />
            <main className="profile-page">
            <PageBg />
                <section className="relative py-16 bg-blueGray-200">
                    <div className="container mx-auto px-4">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                            <div className="px-6">
                            <PageHeadingImage />

                            { (this.state.videoDetail.length > 0) ? (
                                <div>
                                <div className="text-center mt-12">
                                    <h3 className="text-2xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                                    {this.state.videoDetail[0].title.toString()}
                                    </h3>
                                </div>
                                <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                                    <div className="container px-4 mx-auto">
                                        <div className="flex flex-wrap">
                                            <div className="w-full px-4 md:w-12/12">
                                                
                                <div className="mb-3 text-justify">
                                    <p>  {this.state.videoDetail[0].description}</p>
                                </div>
                                                <ReactPlayer url= {this.state.videoDetail[0].video_link} playing={true} controls={true} width={'100%'} height={'650px'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                )
                                :
                                (<p>Please contact Administration.</p>)}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
}
export default VideoView;

