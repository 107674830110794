import React,{Component} from "react";
import Navbar from "../../components/Navbars/AuthNavbar";
import Footer from "../../components/Footers/Footer";
import {Link} from "react-router-dom";
import { Constant } from "../../helpers/Constant";
import PageBg from '../static-pages/PageBg';
import PageHeadingImage from '../static-pages/PageHeadingImage';

class Sitemap extends Component
{
    constructor(props) {
        super();
    }
    render() {
        return (
            <>
                <Navbar transparent />
                <main className="profile-page">
                <PageBg />
                    <section className="relative py-16 bg-blueGray-200">
                        <div className="container mx-auto px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                                <div className="px-6">
                                <PageHeadingImage />
                                    <div className="text-center mt-12">
                                        <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                                            Knowledge Thrusters
                                        </h3>
                                    </div>
                                    <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                                        <div className="container px-4 mx-auto">
                                            <div className="flex flex-wrap">
                                                <div className="w-1/4 px-4 md:w-6/12"></div>
                                                <div className="w-1/4 px-4 md:w-12/12">
                                                    <Link to="/" target="_blank">
                                                        <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-4">
                                                            <i className="fa fa-home text-white"></i>
                                                                <p className="text-lg text-white font-semibold">Home</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap mt-4">
                                                <div className="w-1/4 px-4 md:w-12/12">
                                                    <Link to="/about" target="_blank">
                                                        <div className="bg-red-600 shadow-lg rounded-lg text-center p-4">
                                                            <i className="fa fa-user text-white"></i>
                                                            <p className="text-lg text-white font-semibold">About</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="w-1/4 px-4 md:w-12/12">
                                                    <Link to="/terms-and-conditions" target="_blank">
                                                        <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-4">
                                                            <i className="fa fa-list text-white"></i>
                                                            <p className="text-lg text-white font-semibold">Terms & Conditions</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="w-1/4 px-4 md:w-12/12">
                                                    <Link to="/privacy-policy" target="_blank">
                                                        <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-4">
                                                            <i className="fa fa-address-book text-white"></i>
                                                            <p className="text-lg text-white font-semibold">Privacy Policy</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="w-1/4 px-4 md:w-12/12">
                                                    <Link to="/contact" target="_blank">
                                                        <div className="bg-lightBlue-500 shadow-lg rounded-lg text-center p-4">
                                                            <i className="fa fa-user-circle text-white"></i>
                                                            <p className="text-lg text-white font-semibold">Contact</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                                { Constant.navbar_list.map((value,index)=>{
                                                return (
                                                <div className="w-1/4 px-4 md:w-12/12" key={index}>
                                                    <Link to={"/" + value.url} target="_blank">
                                                        <div className={ value.bg + " shadow-lg rounded-lg text-center p-4"}>
                                                            <i className={value.icon + " text-white"}></i>
                                                            <p className="text-lg text-white font-semibold">{value.name}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                                )
                                            })
                                                }
                                                {/* <div className="w-1/4 px-4 md:w-12/12">
                                                    <Link to="/codeigniter" target="_blank">
                                                        <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-4">
                                                            <i className="fab fa-free-code-camp text-white"></i>
                                                            <p className="text-lg text-white font-semibold">Codeigniter</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="w-1/4 px-4 md:w-12/12">
                                                    <Link to="/javascript" target="_blank">
                                                        <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-4">
                                                            <i className="fab fa-js text-white"></i>
                                                            <p className="text-lg text-white font-semibold">JavaScript</p>
                                                        </div>
                                                    </Link>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </>
        );
    }
}
export default Sitemap;