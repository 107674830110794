import React from "react";
import {Link} from "react-router-dom";
import { Constant } from "../../helpers/Constant";
import KTteamImg from '../../assets/img/kt-team2.jpg';
export default function CategorySection() {
    return (
        <>
            <section className="pb-20 bg-blueGray-200 -mt-24">
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap">
                    { Constant.categories.map((value,index)=>{
                        
                    return (<div className={value.className} key={index}>
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className={"text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full " + value.bg}>
                                        <i className={value.icon}></i>
                                    </div>
                                    <h6 className="text-xl font-semibold">{value.name}</h6>
                                    <p className="mt-2 mb-4 text-blueGray-500">
                                        {value.desc}
                                    </p>
                                </div>
                            </div>
                        </div>)
                    })}
{/* <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                                        <i className="fab fa-php"></i>
                                    </div>
                                    <h6 className="text-xl font-semibold">PHP</h6>
                                    <p className="mt-2 mb-4 text-blueGray-500">
                                        Learn php programming from these free basic video tutorials.
                                    </p>
                                </div>
                            </div>
                        </div> */}


                        {/* <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                                        <i className="fab fa-php"></i>
                                    </div>
                                    <h6 className="text-xl font-semibold">PHP</h6>
                                    <p className="mt-2 mb-4 text-blueGray-500">
                                        Learn php programming from these free basic video tutorials.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="w-full md:w-4/12 px-4 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                                        <i className="fab fa-free-code-camp"></i>
                                    </div>
                                    <h6 className="text-xl font-semibold">CodeIgniter</h6>
                                    <p className="mt-2 mb-4 text-blueGray-500">
                                        Some of the basic coding information compiled in the form of video tutorials.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                                        <i className="fab fa-js"></i>
                                    </div>
                                    <h6 className="text-xl font-semibold">JavaScript</h6>
                                    <p className="mt-2 mb-4 text-blueGray-500">
                                        Some examples are shown in the video tutorial. Go check them out.
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="flex flex-wrap items-center mt-32">
                        <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                <i className="fas fa-user-friends text-xl"></i>
                            </div>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Know About Us
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                Knowledge Thrusters is created to teach basic coding techniques in a simplified manner.
                                The video tutorials are designed in such a way that help you get the concept easily.
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                            </p>
                            <a target="_blank" href="https://www.youtube.com/channel/UCUly2tkwJ23fkKQ3uASf8qg" className="font-bold text-blueGray-700 mt-8">
                                Check Youtube Channel
                            </a>
                        </div>

                        <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                                <img
                                    alt="..."
                                    src={KTteamImg}
                                    // src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                                    className="w-full align-middle rounded-t-lg"
                                />
                                <blockquote className="relative p-8 mb-4">
                                    <svg
                                        preserveAspectRatio="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 583 95"
                                        className="absolute left-0 w-full block h-95-px -top-94-px"
                                    >
                                        <polygon
                                            points="-30,95 583,95 583,65"
                                            className="text-lightBlue-500 fill-current"
                                        ></polygon>
                                    </svg>
                                    <h4 className="text-xl font-bold text-white">
                                        Quote
                                    </h4>
                                    <p className="text-md font-light mt-2 text-white">
                                        Doctor Who: You want weapons ?
                                        We are in a library. Books are the best weapon in the world. This room's the greatest arsenal we could have. Arm yourself !!!
                                    </p>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}