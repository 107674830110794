import React, { Component }  from "react";
import { Constant } from "../../helpers/Constant";
import ReactPlayer from 'react-player'
import Navbar from "../../components/Navbars/AuthNavbar.js";
import Footer from "../../components/Footers/Footer.js";
import PageBg from '../static-pages/PageBg';
import axios from "axios";
import Toaster from "../../helpers/Toaster";
import PageHeadingImage from '../static-pages/PageHeadingImage';
import { Link } from "react-router-dom";
import KTLogo from '../../assets/img/kt-logo2.jpeg';
import comingSoon from '../../assets/img/coming-soon.png';

class ProgramView extends Component {
    constructor(props) {
        super();
        this.state = {
          programDetail: [],
          heading: ''
        };
      }
    componentDidMount() {
        this.programView();
        let pageUrl = window.location.pathname;
        // let pageUrl = this.props.location.pathname; 
        let pageUrl2 =pageUrl.split('/').at(-1);
        let finalHeading = pageUrl2.charAt(0).toUpperCase() + pageUrl2.slice(1).replace('-',' ');
        this.setState({heading:finalHeading});
      }
    
      programView() {
        let module = window.location.pathname;
     //   var module = this.props.location.pathname;
        var program = module.split('/').at(-1);

        axios
          .get(Constant.server_url + `program-details/` + program)
          .then((res) => {
              var programDetails = res.data.data;
              if(programDetails.length > 0)
              {
                this.setState({ programDetail: programDetails });
              }
            
          })
          .catch((err) => {
            new Toaster({ type: "error", msg: "Something Went Wrong." });
          });
      }
      render() {
    return (
        <>
            <Navbar transparent />
            <main className="profile-page">
            <PageBg />
                <section className="relative py-16 bg-blueGray-200">
                    <div className="container mx-auto px-4">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                            <div className="px-6">
                            <PageHeadingImage />

                            <div className="text-center mt-12">
                    <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                      {this.state.heading}
                    </h3>
                  </div>
                  <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                    <div className="container px-4 mx-auto">
                        <div className="flex flex-wrap">
                        {this.state.programDetail.length > 0 ? (
                      this.state.programDetail.map((value, index) => (
                        <div className="w-1/2 px-4 md:w-4/12">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ">
                     
                          {(value.video_image == 'null') ? 
                          (<img
                            key={'i'+index}
                              alt="..."
                              src={KTLogo}
                              className="w-full align-middle rounded-t-lg videoImage"
                            />)
                            :
                            ( 
                              (value.video_image.includes('https')) ? 
                            <img  key={'i'+index} alt="..." src={value.video_image}  className="w-full align-middle rounded-t-lg videoImage" /> 
                            :  
                            <img alt="..." src={require("../../assets/img/" + value.video_image).default} className="w-full align-middle rounded-t-lg videoImage" /> 
                            )
                          }
                          <blockquote className="relative p-8 mb-4">
                            <h4 className="text-md font-bold ">
                              {value.title}
                            </h4>
                                <p className="text-md font-light mt-2 ">
                              <Link
                                to={"/video-details/" + value.slug}
                                className="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3  rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button" key={index}
                              >
                                <i className="fas fa-eye"></i> View
                              </Link>
                            </p>
                          </blockquote>
                        </div>
                    </div>
                      ))) : (
                        <div className="w-full ">
                           <div className="text-center">
                            <img
                              alt="..."
                              src={comingSoon}
                              className="inline-flex h-auto align-middle border-none  "
                            />
                          </div>
                        </div>
                      )}
                      </div>
                      
                    </div>
                  </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
}
export default ProgramView;

