import React from "react";
// components
import Navbar from "../../components/Navbars/AuthNavbar.js";
import Footer from "../../components/Footers/Footer.js";
import Home from './Home';
import Category from './CategorySection';
import Company from './CompanySection';
import Contact from './Contact';

export default function Landing() {
  return (
    <>
      <Navbar transparent />
      <main>
        <Home />
        <Category />
        <Company />
        <Contact />
      </main>
      <Footer />
    </>
  );
}
