import React from "react";

import Navbar from "../../components/Navbars/AuthNavbar.js";
import Footer from "../../components/Footers/Footer.js";
import AboutImg from "../../assets/img/about.svg";
import PageBg from '../static-pages/PageBg';
import PageHeadingImage from '../static-pages/PageHeadingImage';

export default function About() {
    return (
        <>
            <Navbar transparent />
            <main className="profile-page">
            <PageBg />
                <section className="relative py-16 bg-blueGray-200">
                    <div className="container mx-auto px-4">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                            <div className="px-6">
                            <PageHeadingImage />
                                <div className="text-center mt-12">
                                    <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                                        About Us
                                    </h3>
                                </div>
                                <div className="mt-10 py-10 border-t border-blueGray-200">
                                    <div className="container px-4 mx-auto">
                                        <div className="flex flex-wrap">

                                            <div className="w-1/4 px-4 md:w-8/12">
                                        <h2 className="text-xl font-semibold">Our Objective</h2>
                                            <p className="mt-2 mb-4">
                                                Everybody has a thrust in their life for something. Some have in music, in dance , in story writing, in story telling, and many more. Whatever you learn from your thrust that the knowledge you gain. The Experience you add to your skills.
                                            </p>
                                            <p>
                                                This Channel help the thrusters who are looking their career in web development.  Tutorials in the field of web development will be uploaded so you can learn something useful and create something wonderful in your professional career that leads you to higher level.
                                            </p>
                                        </div>

                                            <div className="w-1/4 px-4 md:w-4/12">
                                                <img src={AboutImg}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
