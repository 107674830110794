export const Constant = {
    server_url : (process.env.NODE_ENV === 'development' ? "http://127.0.0.1:3001/" : "http://knowledgethrusters.com/api/"),
    navbar_list : [
        {
        url: "php",
        name: "PHP",
        dropdown:0,
        bg:'bg-red-600',
        icon:'fab fa-php'
    },  {
        url: "codeigniter",
        name: "CodeIgniter",
        dropdown:0,
        bg:'bg-yellow-500',
        icon:'fab fa-free-code-camp '
    }, {
        url: "laravel",
        name: "Laravel",
        dropdown:0,
        bg:'bg-teal-500',
        icon:'fab fa-laravel'
    }, {
        url: "git",
        name: "Git",
        dropdown:0,
        bg:'bg-lightBlue-600',
        icon:'fab fa-git'
    }, {
        url: "javascript",
        name: "JavaScript",
        dropdown:0,
        bg:'bg-purple-500',
        icon:'fab fa-js'
    },{
        url: "html",
        name: "HTML",
        dropdown:0,
        bg:'bg-teal-500',
        icon:'fab fa-html5'
    },
    // {
    //     url: "css",
    //     name: "CSS",
    //     dropdown:0,
    //     bg:'bg-yellow-500',
    //     icon:'fab fa-css3'
    // }, 
    {
        url: "vue",
        name: "Vue",
        dropdown:0,
        bg:'bg-red-500',
        icon:'fab fa-vuejs'
    },{
        url: "mysql",
        name: "MySQL",
        dropdown:0,
        bg:'bg-purple-500',
        icon:'fa fa-database'
    },{
        url: "programs",
        name: "Programs",
        dropdown:0,
        bg:'bg-teal-500',
        icon:'fa fa-list'
    }],
    categories:[
        {
            name: "PHP",
            bg:'bg-red-400',
            icon:'fab fa-php',
            desc: 'Learn php programming from these free basic video tutorials.',
            className:'lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center'
        },
        {
            name: "CodeIgniter",
            bg:'bg-lightBlue-400',
            icon:'fab fa-free-code-camp',
            desc: 'Some of the basic coding information compiled in the form of video tutorials.',
            className:'w-full md:w-4/12 px-4 text-center'
        },
        {
            name: "JavaScript",
            bg:'bg-emerald-400',
            icon:'fab fa-js',
            desc: ' Some examples are shown in the video tutorial. Go check them out.',
            className:'pt-6 w-full md:w-4/12 px-4 text-center'
        },
        {
            name: "Laravel",
            bg:'bg-purple-500',
            icon:'fab fa-laravel',
            desc: 'Learn php programming from these free basic video tutorials.',
            className:'lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center'
        },
        {
            name: "Git",
            bg:'bg-orange-500',
            icon:'fab fa-git',
            desc: 'Learn php programming from these free basic video tutorials.',
            className:'lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center'
        },
        {
            name: "HTML",
            bg:'bg-lightBlue-500',
            icon:'fab fa-html5',
            desc: 'Learn php programming from these free basic video tutorials.',
            className:'lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center'
        }
    ]
};
