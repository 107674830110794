import React, { Component } from 'react';
import { Route, Routes} from "react-router-dom";
import Video from './views/videos/Video';
import VideoView from './views/videos/VideoView';
import Landing from './views/home/Landing';
import About from './views/home/About';
import Contact from './views/static-pages/Contact';
import Sitemap from './views/static-pages/Sitemap';
import PrivacyPolicy from './views/static-pages/PrivacyPolicy';
import TermsAndCondition from './views/static-pages/TermsAndCondition';
import Program from './views/program/Program';
import ProgramView from './views/program/ProgramView';

class App extends Component {
  render() {
    return (
          <Routes>
              {/*<Route path={"/category/:id"} component={VideoView}></Route>*/}
              <Route key="php" path={"/php"} element={<Video/>}></Route>
              <Route key="codeigniter" path={"/codeigniter"} element={<Video/>}></Route>
              <Route key="laravel" path={"/laravel"} element={<Video/>}></Route>
              <Route key="git" path={"/git"} element={<Video/>}></Route>
              <Route key="javascript" path={"/javascript"} element={<Video/>}></Route>
              <Route key="html" path={"/html"} element={<Video/>}></Route>
              <Route key="cdd" path={"/css"} element={<Video/>}></Route>
              <Route key="vue" path={"/vue"} element={<Video/>}></Route>
              <Route key="mysql" path={"/mysql"} element={<Video/>}></Route>
              <Route key="video-details" path={"/video-details/:id"} element={<VideoView/>}></Route>
              <Route key="programs" path={"/programs"} element={<Program/>}></Route>
              <Route key="program-details" path={"/program-details/:id"} element={<ProgramView/>}></Route>
              <Route key="about" path={"/about"} element={<About/>}></Route>
              <Route key="contact-us" path={"/contact-us"} element={<Contact/>}></Route>
              <Route key="sitemap" path={"/sitemap"} element={<Sitemap/>}></Route>
              <Route key="privacy-policy" path={"/privacy-policy"} element={<PrivacyPolicy/>}></Route>
              <Route key="terms-and-conditions" path={"/terms-and-conditions"} element={<TermsAndCondition/>}></Route>
            <Route exact path={"/"}  element={<Landing/>}></Route>
          </Routes>
    );
  }
}

export default App;