import React from "react";
import Ktlogo from '../../assets/img/kt-logo2.jpeg';

const PageHeadingImage = () => {
  return (
    <div className="flex flex-wrap justify-center">
      <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
        <div className="relative">
          <img
            alt="..."
            src={Ktlogo}
            className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
          />
        </div>
      </div>
      <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
        <div className="py-6 px-3 mt-32 sm:mt-0"></div>
      </div>
      <div className="w-full lg:w-4/12 px-4 lg:order-1">
        <div className="flex justify-center py-4 lg:pt-4 pt-8"></div>
      </div>
    </div>
  );
};

export default PageHeadingImage;
