import React,{Component} from "react";
import Navbar from "../../components/Navbars/AuthNavbar";
import ContactImg from "../../assets/img/contact-3.svg";
import Footer from "../../components/Footers/Footer";
import axios from "axios";
import {Constant} from "../../helpers/Constant";
import Toaster from "../../helpers/Toaster";

import PageBg from '../static-pages/PageBg';
import PageHeadingImage from '../static-pages/PageHeadingImage';

class Contact extends Component
{
    constructor(props) {
        super();
        this.state = {
            fullName:'',
            email:'',
            message:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputHandler = this.inputHandler.bind(this);
    }
    inputHandler(event,type){
        this.setState({
            [type] : event.target.value
        });
    }
    handleSubmit(e){
        e.preventDefault();
        const fd = this.state;
        axios.post(Constant.server_url + `contact`,  fd )
            .then(res => {
                new Toaster({type :"success" , msg:"Your query is submitted successfully."});
            }).catch((err)=>{
            new Toaster({type :"error" , msg:"Your query is not submitted. Please Try Again."})
        });
    }
    render() {
        return (
            <>
                <Navbar transparent />
                <main className="profile-page">
                <PageBg />
                    <section className="relative py-16 bg-blueGray-200">
                        <div className="container mx-auto px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                                <div className="px-6">
                                   <PageHeadingImage />
                                    <div className="text-center mt-12">
                                        <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                                            Contact Us
                                        </h3>
                                    </div>
                                    <div className="mt-10 py-10 border-t border-blueGray-200">
                                        <div className="container px-4 mx-auto">
                                            <div className="flex flex-wrap">
                                                <div className="w-1/4 px-4 md:w-8/12">
                                                    <div
                                                        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                                                        <div className="rounded-t mb-0 px-6 py-6">
                                                            <div className="text-center mb-3">
                                                                <h4 className="text-blueGray-500 text-sm font-bold">Lets Connect</h4>
                                                            </div>
                                                            <hr className="mt-6 border-b-1 border-blueGray-300"/>
                                                        </div>
                                                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                                            <form method="post" onSubmit={this.handleSubmit}>
                                                                <div className="relative w-full mb-3">
                                                                    <label
                                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                                        htmlFor="full-name" >  Full Name  </label>
                                                                    <input
                                                                        type="text"
                                                                        ref="full_name"
                                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                        placeholder="Full Name"
                                                                        onChange={(event)=>{this.inputHandler(event,"fullName")}}
                                                                        required
                                                                    />

                                                                </div>
                                                                <div className="relative w-full mb-3">
                                                                    <label
                                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                                        htmlFor="email"
                                                                    >
                                                                        Email
                                                                    </label>
                                                                    <input
                                                                        type="email"
                                                                        ref="email"
                                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                        placeholder="Email"
                                                                        onChange={(event)=>{this.inputHandler(event,"email")}}
                                                                        required
                                                                    />
                                                                </div>

                                                                <div className="relative w-full mb-3">
                                                                    <label
                                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                                        htmlFor="message"
                                                                    >
                                                                        Message
                                                                    </label>
                                                                    <textarea
                                                                        rows="4"
                                                                        cols="80"
                                                                        ref="message"
                                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                                        placeholder="Type a message..."
                                                                        onChange={(event)=>{this.inputHandler(event,"message")}}
                                                                        required
                                                                    />
                                                                </div>

                                                                <div className="text-center mt-6">
                                                                    <button
                                                                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                                        type="submit">Send
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="w-1/4 px-4 md:w-4/12 relative">
                                                    <img src={ContactImg} className="absolute" style={{bottom:"196px"}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </>
        );
    }
}
export default Contact;